export const lightTheme = {
  h1weight: 600,
  themeToggle: "rgb(75,75,75)",
  background: "#f6f8fa",
  foreground: "#ffffff",
  foregroundBorder: "#e1e4e8",
  textPrimary: "rgb(8,8,8)",
  textHighlight: "#37A1D8",
  textHighlightShadow: "#9480FF",
  textSecondary: "rgb(50,50,50)",
  secondary: "#37A1D8",
  mutedSecondary: "#ffffff",
  button: "#ffffff",
  //buttonBorder: '#e1e4e8',
  buttonHover: "rgba(157, 201, 255, 0.32)",
  buttonText: "#37A1D8",
  buttonTextHover: "#337ab7",
  skillTag: "clear",
  textSkillTag: "#5e9516",
  horizontalRuleColor: "rgb(225,225,225)",
  boxShadow: "0px 1px 7px -1px #e1e4e8",
  edu_crest:
    "invert(0%) sepia(0%) saturate(23%) hue-rotate(60deg) brightness(101%) contrast(105%);",
}

export const darkTheme = {
  h1weight: 500,
  themeToggle: "rgb(242,242,242)",
  background: "rgb(21,19,32)",
  foreground: "rgb(34,33,45)",
  foregroundBorder: "rgb(34,33,45)",
  textPrimary: "rgb(242,242,242)",
  textHighlight: "rgb(148,128,255)",
  textHighlightShadow: "rgba(148,128,255,0.1)",
  textSecondary: "rgb(153,153,153)",
  secondary: "rgb(148,128,255)",
  mutedSecondary: "#2b2640",
  button: "rgb(148,128,255)",
  //buttonBorder: 'rgb(148,128,255)',
  buttonHover: "#ff80bf",
  buttonText: "black",
  buttonTextHover: "rgb(34,33,45)",
  skillTag: "rgb(35, 46, 44)",
  textSkillTag: "rgb(119, 203, 185)",
  horizontalRuleColor: "",
}

export const darkTheme2 = {
  h1weight: 500,
  themeToggle: "rgb(242,242,242)",
  background: "#0d1117",
  foreground: "#161b22",
  foregroundBorder: "#21262d",
  textPrimary: "rgb(242,242,242)",
  textHighlight: "#8082ff",
  textHighlightShadow: "rgba(148,128,255,0.1)",
  textSecondary: "rgb(153,153,153)",
  secondary: "#8082ff",
  mutedSecondary: "#262a40",
  button: "#8082ff",
  //buttonBorder: 'rgb(148,128,255)',
  buttonHover: "#ff80bf",
  buttonText: "#0f111a",
  buttonTextHover: "rgb(34,33,45)",
  skillTag: "rgb(35, 46, 44)",
  textSkillTag: "rgb(119, 203, 185)",
  horizontalRuleColor: "",
  boxShadow: "none",
  edu_crest:
    "invert(64%) sepia(30%) saturate(0%) hue-rotate(284deg) brightness(90%) contrast(82%)",
}
